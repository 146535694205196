import useFakeFetch from 'hooks/useFakeFetch';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import WidgetSectionTitle from 'widgets/WidgetSectionTitle';
import PricingDefaultCard from './PricingDefaultCard';
export const pricingData = [
  {
    id: 0,
    name: 'load_data_pack',
    title: 'Load Historical Data',
    subTitle:
      'Get a full report on your historical data with a one-time payment',
    price: 49.9,
    purchaseType: 'oneOff',
    buttonText: 'Purchase',
    isFeatured: true,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimitted contributors' },
      {
        id: 1,
        title: "Every day, you'll have your new data updated",
        blocked: true
      },
      { id: 2, title: 'Commits and Pull request history' },
      { id: 3, title: 'Investment balance' },
      { id: 4, title: 'Developers radar' },
      { id: 5, title: 'Developers map' },
      { id: 6, title: '1 year data retention' },

      { id: 7, title: 'Give access to other users', blocked: true },
      { id: 8, title: 'Smart notifications', blocked: true },
      {
        id: 9,
        title: 'AI weekly and monthly review',
        blocked: true,
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 2,
    name: 'base_pack',
    title: 'Base Plan',
    subTitle: 'Subscribe to get your new data daily and more funcionalities',
    price: 14.9,
    purchaseType: 'recurring',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimitted contributors' },
      { id: 1, title: "Every day, you'll have your new data updated" },
      { id: 2, title: 'Commits and Pull request history' },
      { id: 3, title: 'Investment balance' },
      { id: 4, title: 'Developers radar' },
      { id: 5, title: 'Developers map' },
      { id: 6, title: '1 year data retention' },

      { id: 7, title: 'Give access to other users', blocked: true },
      { id: 8, title: 'Smart notifications', blocked: true },
      {
        id: 9,
        title: 'AI weekly and monthly review',
        blocked: true,
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 3,
    name: 'advanced_pack',
    title: 'Advanced Plan',
    subTitle: 'Enjoy all the benefits of the Base Plan, plus extra features',
    price: 24.9,
    purchaseType: 'recurring',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimitted contributors' },
      { id: 1, title: "Every day, you'll have your new data updated" },
      { id: 2, title: 'Commits and Pull request history' },
      { id: 3, title: 'Investment balance' },
      { id: 4, title: 'Developers radar' },
      { id: 5, title: 'Developers map' },
      { id: 6, title: '1 year data retention' },

      { id: 7, title: 'Give access up to 5 Github users' },
      { id: 8, title: 'Smart notifications' },
      {
        id: 9,
        title: 'AI weekly and monthly review',
        tag: { label: 'New', type: 'success' }
      }
    ]
  },
  {
    id: 4,
    name: 'enterprise_pack',
    title: 'Enterprise Plan',
    subTitle:
      'All the perks of the Advanced plan, expanded with more seats for sharing!',
    price: 34.9,
    purchaseType: 'recurring',
    buttonText: 'Subscribe',
    isFeatured: false,
    featureTitle: '',
    features: [
      { id: 1, title: 'Unlimitted contributors' },
      { id: 1, title: "Every day, you'll have your new data updated" },
      { id: 2, title: 'Commits and Pull request history' },
      { id: 3, title: 'Investment balance' },
      { id: 4, title: 'Developers radar' },
      { id: 5, title: 'Developers map' },
      { id: 6, title: '1 year data retention' },

      { id: 7, title: 'Give access up to 10 Github users' },
      { id: 8, title: 'Smart notifications' },
      {
        id: 9,
        title: 'AI weekly and monthly review',
        tag: { label: 'New', type: 'success' }
      }
    ]
  }
];

const searchParams = new URLSearchParams(window.location.search);
const plan = searchParams.get('plan');
if (plan === 'out-of-free-trial') {
  toast.warning(
    'Your free trial has ended! Upgrade to a plan now to continue enjoying our services! 😊',
    { position: 'top-right', autoClose: false }
  );
}

const PricingDefault = ({ isPurchasable }) => {
  const { loading: priceLoading, data: pricing } = useFakeFetch(
    pricingData,
    500
  );
  return (
    <>
      {isPurchasable && (
        <WidgetSectionTitle
          icon="credit-card"
          title="Select your plan"
          subtitle="<p>During the free trial period, you will have access to the Base Plan for 15 days at no cost. Your data will be updated daily. Additionally, we will initially load the last 7 days of your organization's activity on GitHub.</p><p>Below are Gitlights' plans for continuing to use the application once the trial period has ended. There are three monthly subscription plans: Base, Advanced and Enterprise Plans. Additionally, you have the option to purchase part of your historical data with the 'Load Historical Data' plan. By purchasing historical data, you acquire 5000 GitHub credits to access their API.</p>"
          transform="shrink-4"
          className="mb-4 mt-2"
        />
      )}
      <Card className="mb-3">
        <Card.Body>
          <Row className="g-0">
            {priceLoading ? (
              <Col xs={12} className="py-4">
                <Spinner
                  className="position-absolute start-50"
                  animation="grow"
                />
              </Col>
            ) : (
              pricing.map(pricing => (
                <PricingDefaultCard
                  key={pricing.id}
                  pricing={pricing}
                  isPurchasable={isPurchasable}
                />
              ))
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

PricingDefault.propTypes = {
  isPurchasable: PropTypes.bool
};
PricingDefault.defaultProps = {
  isPurchasable: true
};
export default PricingDefault;
