// Base variable to define the public subdomain prefix
const PUBLIC_SUBDOMAIN_PREFIX = 'public.gitlights.com';
//const PUBLIC_SUBDOMAIN_PREFIX = 'localhost';

// Utility to check if the current hostname uses the public subdomain
const isPublicDomain = () => {
  const hostname = window.location.hostname;
  return hostname.startsWith(PUBLIC_SUBDOMAIN_PREFIX);
};

// Utility to extract the organization from the current pathname
// Returns undefined if the domain is not public or the org is not found
const getOrgFromPath = () => {
  if (!isPublicDomain()) {
    return undefined;
  }

  const pathname = window.location.pathname;
  const pathSegments = pathname.split('/');

  if (pathSegments.length > 1 && pathSegments[1]) {
    return pathSegments[1];
  }

  return undefined;
};

export { isPublicDomain, getOrgFromPath };
