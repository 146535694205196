import AdvanceSearchTable from 'components/common/custom-charts/AdvanceSearchTable';
import MultiLineStackHistogramV2 from 'components/common/custom-charts/MultiLineStackHistogram';
import InfoModal from 'components/common/custom-components/InfoModal';
import {
  DashboardFilterDevelopersContext,
  DashboardFilterRepositoriesContext,
  DashboardFiltersContext
} from 'context/Context';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { ScatterChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { checkIsDataLoaded, EmptyPage } from 'emptyPage';
import { getColor, rgbaColor } from 'helpers/utils';
import axiosInstance from 'http/axiosConfig';
import {
  default as React,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
echarts.use([ScatterChart]);
var developersList;
var showMainLoading = true;
const DeveloperDetailView = () => {
  const [isDataLoaded] = useState(checkIsDataLoaded());

  const [isMainLoading, setIsMainLoading] = useState(true);
  const [isSecondaryLoading, setIsSecondaryLoading] = useState(true);
  const [commitInfoByDevTable, setCommitInfoByDevTable] = useState([]);
  const [commitsByInvestmentType, setCommitsByInvestmentType] = useState([]);

  const [prsInfoByDevTable, setPrsInfoByDevTable] = useState([]);
  const filtersContext = useContext(DashboardFiltersContext);
  const developersFiltersContext = useContext(DashboardFilterDevelopersContext);
  const repositoriesFiltersContext = useContext(
    DashboardFilterRepositoriesContext
  );
  const [radarChart, setRadarChart] = useState([]);
  const [pieCharts, setPieCharts] = useState([]);
  const [commitsEvolutionRSIEMA, setCommitsEvolutionRSIEMA] = useState([]);

  const [prs_evolution_RSI_EMA, setPrsEvolutionRSIEMA] = useState([]);
  const [additions_deletions_by_time, setAdditionsDeletionsByTime] = useState(
    []
  );
  const [reviews_conv_comm_by_time, setReviewsConvCommByTime] = useState([]);

  const [mainData, setMainData] = useState([]);

  const [comparationMode, setComparationMode] = useState(false);
  const [developerToCompare, setDeveloperToCompare] = useState();

  const hasFetchingRef = useRef(false);
  useEffect(() => {
    if (hasFetchingRef.current) {
      return;
    }
    hasFetchingRef.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const dev1 = urlParams.get('dev1');
    if (dev1 == null) {
      alert('no developer!!');
    }
    if (showMainLoading) {
      showMainLoading = false;
      setIsMainLoading(true);
    }
    setIsSecondaryLoading(true);

    axiosInstance
      .get('/get-developer-dashboard/', {
        params: {
          startDate: filtersContext.filters.startDate,
          dev1: dev1,
          dev2: developerToCompare,
          endDate: filtersContext.filters.endDate,
          repositories:
            repositoriesFiltersContext.repositoriesFilter.repositories.toString()
        }
      })
      .then(response => {
        const data = response.data.dashboard_data;

        setRadarChart(data.radar_chart);
        setPieCharts(data.commits_by_repository_charts);
        setCommitsEvolutionRSIEMA(data.commits_evolution_RSI_EMA);
        setPrsEvolutionRSIEMA(data.prs_evolution_RSI_EMA);
        setAdditionsDeletionsByTime(data.additions_deletions_by_time);
        setReviewsConvCommByTime(data.reviews_conv_comm_by_time);
        setPrsInfoByDevTable(data.prs_info_by_dev_table);
        setCommitInfoByDevTable(data.commits_info_by_dev_table);
        setCommitsByInvestmentType(data.commits_by_investment_type);

        console.log(data.commits_evolution_RSI_EMA);
        setMainData(data.main_data);

        setIsSecondaryLoading(false);
        setIsMainLoading(false);
        hasFetchingRef.current = false;
      })
      .catch(error => {
        hasFetchingRef.current = false;
        console.error(error);
      });
  }, [
    filtersContext,
    developersFiltersContext,
    repositoriesFiltersContext,
    developerToCompare
  ]);

  const getOption2 = (position, center) => ({
    legend: {
      left: position,
      top: 'center',
      orient: 'vertical',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: center,
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'left'
        },
        labelLine: {
          show: false
        },
        data: pieCharts[0]
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [5, 5],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });
  const getOption3 = (position, center) => ({
    legend: {
      left: position,
      top: 'center',
      orient: 'vertical',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: center,
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'left'
        },
        labelLine: {
          show: false
        },
        data: pieCharts[1]
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [5, 5],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });

  const getOption = () => ({
    legend: {
      orient: 'horizontal',
      left: 'center',
      top: 'top',
      textStyle: {
        color: getColor('gray-600')
      }
    },
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },

    radar: {
      indicator: radarChart.indicator,
      radius: 150,
      splitLine: {
        lineStyle: {
          color: rgbaColor(getColor('gray-700'))
        }
      }
    },

    series: [
      {
        type: 'radar',
        data: [
          {
            value: radarChart.data[0],
            name: radarChart.developers[0],
            itemStyle: {
              color: getColor('primary')
            }
          },
          {
            value: radarChart.data[1],
            name: radarChart.developers[1],
            itemStyle: {
              color: getColor('warning')
            }
          }
        ]
      }
    ]
  });

  const onChange = developer => {
    console.log(developer);
    setComparationMode(true);
    setDeveloperToCompare(developer.value);
  };
  const loadOptions = (inputValue, callback) => {
    axiosInstance
      .get('/get-developers-list/')
      .then(response => {
        developersList = response.data.developers_list;
      })
      .catch(error => {
        console.error(error);
      });

    setTimeout(() => {
      callback(
        developersList.filter(i =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 1000);
  };
  return (
    <>
      {isDataLoaded ? (
        <>
          {isMainLoading ? (
            <Spinner
              className="position-absolute start-50"
              animation="border"
              size="xl"
            />
          ) : (
            <>
              {isSecondaryLoading ? (
                <Spinner
                  className="position-absolute start-50"
                  animation="border"
                  size="xl"
                />
              ) : (
                <></>
              )}
              <Row className="g-3">
                <Col md={4} xxl={4}>
                  <InfoModal
                    dataset={{
                      title: 'Developer Detail Panel',
                      description: `
                      <p>This panel provides detailed information about the developer, with the central component being a polar chart of skills. 
                      The polar chart offers a holistic visualization of developers' performance, presenting six key scores reflecting different aspects 
                      of their contribution to software development.</p>
                      
                      <h5>Key Skill Scores:</h5>
                      <ul>
                        <li><strong>Feature Implementation Score:</strong> Represents the developer's ability to implement new features. Benefits: Indicates the ability to introduce significant changes and improvements to the code. Use: Evaluates efficiency in introducing new features.</li>
                        <li><strong>Agility Score:</strong> Reflects speed and efficiency in task delivery. Benefits: Demonstrates responsiveness and agility in the development process. Use: Identifies overall effectiveness in daily task execution.</li>
                        <li><strong>Collaborative Contribution Score:</strong> Evaluates active participation in code reviews and collaboration. Benefits: Highlights teamwork ability and effective code review. Use: Measures positive influence on code quality through collaboration.</li>
                        <li><strong>Code Improvement Score:</strong> Indicates dedication to refactoring and technical improvements. Benefits: Emphasizes attention to code quality and architecture. Use: Evaluates commitment to sustainability and continuous improvement.</li>
                        <li><strong>Technical Contribution Score:</strong> Measures contribution in technical areas such as security and CI/CD. Benefits: Highlights impact on crucial technical aspects. Use: Evaluates participation in strategic technical initiatives.</li>
                        <li><strong>Fixes Score:</strong> Evaluates effectiveness in identifying and solving problems. Benefits: Reflects the ability to address and correct errors effectively. Use: Measures responsiveness and quality in problem-solving.</li>
                      </ul>
                      
                      <h5>How to Interpret the Chart:</h5>
                      <ul>
                        <li>A symmetrical hexagon represents a balance in all areas.</li>
                        <li>Longer radial distances indicate stronger performance in a specific category.</li>
                        <li>Top Skills (North orientation): Skills related to individual contribution, such as Feature Implementation and Agility.</li>
                        <li>Bottom Skills (South orientation): Skills related to collective contribution, such as Collaborative Contribution and Technical Contribution.</li>
                        <li>Comparing the overall shape of the hexagon helps understand relative strengths and areas for improvement.</li>
                      </ul>
                      
                      <p>This polar chart provides a comprehensive view of the developer's performance, enabling informed decision-making and facilitating the identification of development areas and recognition of strengths.</p>
                    `
                    }}
                  />
                </Col>
                <Col md={4} xxl={4}></Col>
                <Col md={4} xxl={4}>
                  <h6 className="compareWithLabelFilter">Compare with:</h6>
                  <AsyncSelect
                    onChange={onChange}
                    defaultOptions
                    cacheOptions
                    isSearchable
                    classNamePrefix="react-select"
                    placeholder="Type here"
                    loadOptions={loadOptions}
                  />
                </Col>
              </Row>
              <Row className="g-3">
                <Row className="g-3 mb-6">
                  <Col md={3} xxl={3}>
                    <Row className="g-3">
                      <h5>{mainData.developers[0]}</h5>
                      <Col md={4} xxl={4}>
                        <img
                          width="100%"
                          className="rounded-circle "
                          src={mainData.developers_avatars[0]}
                          alt=""
                        />
                      </Col>
                      <Col md={8} xxl={8}>
                        <Row className="g-3">
                          <div className="kpi-developer-detail-left">
                            <span className="kpi-span-developer-detail">
                              <strong>Total commits: </strong>
                              {commitInfoByDevTable.tableData[0]
                                ?.total_commits || 0}
                            </span>
                            <span className="kpi-span-developer-detail">
                              <strong>Additions per commit: </strong>{' '}
                              {commitInfoByDevTable.tableData[0]
                                ?.additions_per_commit || 0}
                            </span>
                            <span className="kpi-span-developer-detail">
                              <strong>Deletions per commit: </strong>{' '}
                              {commitInfoByDevTable.tableData[0]
                                ?.deletions_per_commit || 0}
                            </span>
                            <span className="kpi-span-developer-detail">
                              <strong>Total PRs: </strong>{' '}
                              {prsInfoByDevTable.tableData[0]?.total_prs || 0}
                            </span>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="g-3">
                      <ReactEChartsCore
                        echarts={echarts}
                        style={{ height: '12rem' }}
                        option={getOption2('left', ['70%', '50%'])}
                      />
                    </Row>
                  </Col>
                  <Col md={6} xxl={6}>
                    {' '}
                    <ReactEChartsCore
                      echarts={echarts}
                      option={getOption()}
                      style={{ height: '25.5rem' }}
                    />
                  </Col>

                  {comparationMode ? (
                    <Col md={3} xxl={3}>
                      <Row className="g-3">
                        <h5>{mainData.developers[1]}</h5>
                        <Col md={8} xxl={8}>
                          <Row className="g-3">
                            <div className="kpi-developer-detail-left">
                              <span className="kpi-span-developer-detail">
                                <strong>Total commits: </strong>
                                {commitInfoByDevTable.tableData[1]
                                  ?.total_commits || 0}
                              </span>
                              <span className="kpi-span-developer-detail">
                                <strong>Additions per commit: </strong>{' '}
                                {commitInfoByDevTable.tableData[1]
                                  ?.additions_per_commit || 0}
                              </span>
                              <span className="kpi-span-developer-detail">
                                <strong>Deletions per commit: </strong>{' '}
                                {commitInfoByDevTable.tableData[1]
                                  ?.deletions_per_commit || 0}
                              </span>
                              <span className="kpi-span-developer-detail">
                                <strong>Total PRs: </strong>{' '}
                                {prsInfoByDevTable.tableData[1]?.total_prs || 0}
                              </span>
                            </div>
                          </Row>
                        </Col>

                        <Col md={4} xxl={4}>
                          <img
                            width="100%"
                            className="rounded-circle "
                            src={mainData.developers_avatars[1]}
                            alt=""
                          />
                        </Col>
                      </Row>
                      <Row className="g-3">
                        <ReactEChartsCore
                          echarts={echarts}
                          style={{ height: '12rem' }}
                          option={getOption3('right', ['30%', '50%'])}
                        />
                      </Row>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: 'Evolution of Commits with EMA and RSI',
                    description: `
                    <p>This widget offers a detailed visual representation of the historical evolution of commits. The purple bars reflect the absolute values of commits made in specific periods, providing a clear view of the activity. For a smoother interpretation, the blue bars reveal the Exponential Moving Average (EMA) of commits, emphasizing trends over time.</p>

                    <p>Additionally, a green line is incorporated to represent the Relative Strength Index (RSI), a key indicator used in various sectors. In the context of Git development, the RSI offers insight into the team's fitness in terms of the frequency of commits. Both EMA and RSI act on the last 4 samples, providing a more accurate view of recent trends.</p>

                    <h5>Benefits and Possible Interpretations:</h5>
                    <ul>
                      <li><strong>Trend Identification:</strong> The EMA provides a smoothed representation of commit trends over time, aiding in the identification of overarching patterns in the team's contribution. Sudden spikes or declines in EMA may indicate shifts in development momentum.</li>
                      <li><strong>Strength of Development Momentum:</strong> The RSI serves as an indicator of the team's development momentum. A consistently high RSI may suggest a sustained high level of commit activity, while a declining RSI could signify a potential slowdown.</li>
                      <li><strong>Decision Support for Planning:</strong> The combination of EMA and RSI aids in decision-making for future development planning. Teams can use this information to anticipate periods of high or low activity, allowing for more effective resource allocation and project planning.</li>
                    </ul>

                    <p>Overall, this type of chart serves as a powerful tool for project managers, team leads, and developers alike, offering insights that contribute to informed decision-making, improved collaboration, and efficient resource utilization in the Git development environment.</p>
                  `
                  }}
                />
                <MultiLineStackHistogramV2
                  dataset={commitsEvolutionRSIEMA}
                  feature={{
                    dataView: { show: false },
                    magicType: {
                      show: true,
                      type: ['stack', 'tiled']
                    },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }}
                />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title:
                      'Evolution of Code Lines (Added, Deleted, and Balance)',
                    description: `
                        This stacked bar chart provides a detailed view of the temporal evolution of added and deleted lines of code, as well as the balance between them. 
                        Each bar represents the net balance between added and deleted lines in a specific period. This visual approach allows determining whether the development team is 
                        in a phase more focused on new developments or on refactoring and maintenance activities. The presented data is part of the subset of filters applied in the header, 
                        facilitating the adaptation of the visualization to specific analysis criteria.
                      `
                  }}
                />
                <MultiLineStackHistogramV2
                  dataset={additions_deletions_by_time}
                  dailyOrMonthly={false}
                  feature={{
                    dataView: { show: false },
                    magicType: {
                      show: true,
                      type: ['stack', 'tiled']
                    },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }}
                />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: "Developer's Investment Balance",
                    description: `
                    <p>This horizontal bar chart illustrates the developer's "investment balance" expressed as a percentage. The "investment balance" is an indicator that categorizes the developer's contributions into different areas, assigning them a percentage weight based on their impact and relevance.</p>
                    
                    <p>This visual representation allows for an assessment of the proportional distribution of the developer's activities in terms of categories such as new features, code improvements, issue resolution, among others.</p>
                    
                    <p>This chart provides a visual summary of the developer's engagement in different aspects of the development process, allowing for insights into their overall impact and areas of focus.</p>
                  `
                  }}
                />
                <MultiLineStackHistogramV2
                  dataset={commitsByInvestmentType}
                  xIsCategory={false}
                  showPercentage={true}
                  customFormatter={true}
                  feature={{
                    dataView: { show: false },
                    magicType: {
                      show: true,
                      type: ['stack', 'tiled']
                    },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }}
                />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: " Developers' Indicators Table in Commits",
                    description: `
                      This table presents the key indicators related to commits grouped by developer. Using the search function or pagination, you can find specific information about a developer. Clicking on any of the indicators redirects to the developer's detailed page. The included data belongs to the subset of filters applied in the header.
                      <br /><br />
                      <strong>Indicators:</strong>
                      <ul>
                        <li><strong>Total Commits:</strong> The total number of commits made by each developer.</li>
                        <li><strong>Commits by Day:</strong> The distribution of commits over days, providing a view of each developer's daily activity.</li>
                        <li><strong>Additions per Commit:</strong> The average number of lines added per commit, indicating the magnitude of changes in each contribution.</li>
                        <li><strong>Deletions per Commit:</strong> The average number of lines deleted per commit, highlighting the amount of code removed in each change.</li>
                        <li><strong>Files Changed per Commit:</strong> The average number of files modified in each commit, offering information about the extent of the modifications.</li>
                        <li><strong>Message Size per Commit:</strong> The average size of messages associated with each commit, providing insights into the quality and clarity of change descriptions.</li>
                      </ul>
                    `
                  }}
                />
                <AdvanceSearchTable dataset={commitInfoByDevTable} />
              </Row>
              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: 'Evolution of Pull Requests with EMA and RSI',
                    description: `
                    <p>This widget offers a detailed visual representation of the historical evolution of pull requests. The purple bars reflect the absolute values of pull requests made in specific periods, providing a clear view of the activity. For a smoother interpretation, the blue bars reveal the Exponential Moving Average (EMA) of pull requests, emphasizing trends over time.</p>

                    <p>Additionally, a green line is incorporated to represent the Relative Strength Index (RSI), a key indicator used in various sectors. In the context of Git development, the RSI offers insight into the team's fitness in terms of the frequency of pull requests. Both EMA and RSI act on the last 4 samples, providing a more accurate view of recent trends.</p>

                    <h5>Benefits and Possible Interpretations:</h5>
                    <ul>
                      <li><strong>Trend Identification:</strong> The EMA provides a smoothed representation of pull request trends over time, making it easier to identify and understand the overarching patterns in the team's contribution. Sudden spikes or declines in EMA may indicate shifts in development momentum.</li>
                      <li><strong>Strength of Development Momentum:</strong> The RSI serves as an indicator of the team's development momentum. A consistently high RSI may suggest a sustained high level of pull request activity, while a declining RSI could signify a potential slowdown.</li>
                      <li><strong>Decision Support for Planning:</strong> The combination of EMA and RSI aids in decision-making for future development planning. Teams can use this information to anticipate periods of high or low activity, allowing for more effective resource allocation and project planning.</li>
                    </ul>

                    <p>Overall, this type of chart serves as a powerful tool for project managers, team leads, and developers alike, offering insights that contribute to informed decision-making, improved collaboration, and efficient resource utilization in the Git development environment.</p>
                  `
                  }}
                />
                <MultiLineStackHistogramV2
                  dataset={prs_evolution_RSI_EMA}
                  feature={{
                    dataView: { show: false },
                    magicType: {
                      show: true,
                      type: ['stack', 'tiled']
                    },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }}
                />
              </Row>

              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title:
                      'Comments, Reviews, and Conversations in Pull Requests',
                    description: `
                        This stacked bar chart provides a visual representation of the temporal evolution of comments, reviews, and conversations in pull requests. Each segment of the bar represents the contribution of each element, allowing an understanding of how the development team's collaboration has evolved over time. It's important to note that a conversation can contain one or more comments, and these elements can exist outside of a conversation, such as at the root of a pull request. This chart provides a comprehensive view of collaboration dynamics within the team. The presented data is part of the subset of filters applied in the header, facilitating precise customization according to analysis criteria.
                      `
                  }}
                />
                <MultiLineStackHistogramV2
                  dataset={reviews_conv_comm_by_time}
                  feature={{
                    dataView: { show: false },
                    magicType: {
                      show: true,
                      type: ['stack', 'tiled']
                    },
                    restore: { show: true },
                    saveAsImage: { show: true }
                  }}
                />
              </Row>

              <Row className="g-3 mb-6">
                <InfoModal
                  dataset={{
                    title: "Developers' Indicators Table in Pull Requests",
                    description: `
      This table presents the key indicators related to pull requests grouped by developer. You can use the search function or pagination to find specific information about a developer. Clicking on any of the indicators redirects to the developer's detailed page. The included data belongs to the subset of filters applied in the header.
      <br /><br />
      <strong>Indicators:</strong>
      <ul>
        <li><strong>Total PRs:</strong> The total number of pull requests created by each developer.</li>
        <li><strong>Total Reviews:</strong> The total number of reviews conducted by each developer on pull requests (regardless of who authored those PRs).</li>
        <li><strong>Total Conversations:</strong> The total number of conversations initiated by each developer across pull requests.</li>
        <li>
          <strong>Reviews per PR:</strong> The average number of reviews received per pull request for each developer.
          <br /><em>This metric only considers reviews received on pull requests that the developer has created.</em>
        </li>
        <li>
          <strong>Conversations per PR:</strong> The average number of conversations per pull request for each developer.
          <br /><em>This reflects the average number of conversation threads that occur within the pull requests the developer has created.</em>
        </li>
        <li>
          <strong>Comments per Conversation:</strong> The average number of comments per conversation within the pull requests created by each developer.
        </li>
        <li>
          <strong>Time to Merge:</strong> The average time it takes to merge a pull request for each developer, expressed in hours.
          <br /><em>This is calculated based on the pull requests authored by the developer.</em>
        </li>
      </ul>
    `
                  }}
                />
                <AdvanceSearchTable dataset={prsInfoByDevTable} />
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <EmptyPage />
        </>
      )}
    </>
  );
};

export default DeveloperDetailView;
